<template>
  <CRow>
    <CCol sm="6">
      <CCard>
        <CCardHeader>
          <strong>New User</strong>
          <div class="card-header-actions">
            <a
              href="https://coreui.io/vue/docs/components/form-components"
              class="card-header-action"
              rel="noreferrer noopener"
              target="_blank"
            ></a>
            <CLink href="/#/users" class="card-header-action btn-close">
              <CIcon name="cil-x-circle" />
            </CLink>
          </div>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <h6>User Role:</h6>
            <select
              required
              v-model="selectedRole"
              class="role-dropdown"
              placeholder="Select Role"
            >
              <option v-for="role in rolesList" :value="role.ID">
                {{ role.Name }}
              </option>
            </select>
            <CInput placeholder="Name" v-model="name" required>
              <template #prepend-content>
                <CIcon name="cil-user" />
              </template>
            </CInput>
            <CInput
              placeholder="Email"
              type="email"
              v-model="email"
              required
              autocomplete="email"
              valid-feedback
              invalid-feedback="Please provide valid email"
              :is-valid="validateEmail"
            >
              <template #prepend-content>
                <CIcon name="cil-envelope-closed" />
              </template>
            </CInput>
            <CInput
              placeholder="Password"
              type="password"
              v-model="password"
              required
              autocomplete="current-password"
              valid-feedback
              invalid-feedback="Password must be 6 to 30 characters long"
              :is-valid="validatePassword"
            >
              <template #prepend-content>
                <CIcon name="cil-shield-alt" />
              </template>
            </CInput>
            <div class="form-group form-actions">
              <CButton @click="submit" color="success">Create</CButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { listRoles, createUser } from "@/api/user.api.js";

export default {
  name: "NewUser",
  data() {
    return {
      email: null,
      password: null,
      name: null,
      selectedRole: {},
      rolesList: [],
    };
  },
  methods: {
    selectedRoleOption(v) {
    },
    getRoles() {
      listRoles().then((response) => {
        let roles = response.data.data;
        this.rolesList = roles;
      });
    },

    submit() {
      if (
        !this.validatePresence(this.name) ||
        !this.validateEmail(this.email) ||
        !this.validatePassword(this.password) ||
        !this.validateRole(this.selectedRole)
      ) {
        this.showToaster("Please check");
        return;
      }

      createUser(this.email, this.password, this.name, this.selectedRole)
        .then((response) => {
          this.showSuccessToaster("Added Successfully");
          this.$router.push("/users");
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },

    validatePresence(val) {
      if (val == undefined) {
        return "";
      }
      return val ? val.length >= 1 : false;
    },

    validateRole(val) {
      if (val == undefined) {
        return "";
      }
      return val ? val >= 1 : false;
    },

    validateEmail(val) {
      if (val == undefined) {
        return "";
      }
      var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
      if (pattern.test(val)) {
        return true;
      }
      return false;
    },

    validatePassword(val) {
      if (val == undefined) {
        return "";
      }
      var pattern = /[^ ]{6,30}/;
      if (pattern.test(val)) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.getRoles();
  },
};
</script>
